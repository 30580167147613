import styled from "styled-components"
import underline from "images/underline.png"

const HeadingUnderline = styled.h1`
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 100%;
        height: 10px;
        background-image: url("${underline}");
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        bottom: -5px;
    }
`

export default HeadingUnderline